import * as React from "react";
import { Layout, Cta, Splash, FullPortfolioCard } from "components";
import { theme } from "theme";
import { graphql } from "gatsby";

const alternateColors = [theme.secondaryGrey, theme.offBlack];

const Work = ({ data }) => {
  const portfolio_content = data.allContentfulPortfolio.edges;
  return (
    <Layout>
      <Splash
        sectionSubtitle="Blog"
        sectionTitle="Writing"
        sectionText="Collectively, I've received over 100,000 unique views through various channels for my writings. Although I try to set goals to publish my thoughts more often, I'm not always consistent with the follow-through. This is a small sample of the work that I've published."
        type="work"
      />
      {portfolio_content &&
        portfolio_content.map(({ node }, index) => {
          const alternatingIndex = index % 2;
          const backgroundColor = alternateColors[alternatingIndex];
          return (
            <FullPortfolioCard
              key={index}
              slug={node.slug}
              title={node.title}
              gradientStart={node.gradient[0]}
              gradientEnd={node.gradient[1]}
              requirements={node.requirements}
              description={node.description?.description}
              productType={node.productType}
              backgroundColor={backgroundColor}
              alternatingIndex={alternatingIndex}
              img={node.splash}
            />
          );
        })}
      <Cta />
    </Layout>
  );
};

export const query = graphql`
  query {
    allContentfulPortfolio(sort: { order: ASC, fields: order }) {
      edges {
        node {
          ...PortfolioFragment
        }
      }
    }
  }
`;

export default Work;
